import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App/>, document.getElementById('root'));

if ('serviceWorker' in navigator) {
  try {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      //returns installed service workers
      if (registrations.length) {
        for (let registration of registrations) {
          registration.unregister();
          console.log('fjernet sw!');
        }
      }
    });
  } catch (e) {
    console.log('error ved fjerning av sw');
  }
}
