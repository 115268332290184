import React from 'react';


const links = [
  {text: 'Forside', href: '/'},
  {text: 'Kontakt', href: '/kontakt'},
  {text: 'Ansatte', href: '/ansatte'},
  {text: 'Klinikken', href: '/bilder'},
  {text: 'Pris', href: '/pris'},
];


const Header = () => {
  const path = window.location.pathname;
  return (
    <div className="section-dark nav">
      <div className="container">
        <div className="flex space-between">
          <h3>Torvgarden Tannklinikk</h3>
          <div className="flex space-between menu">
            {links.map(l =>
              <a
                key={l.text}
                href={l.href}
                className={path === l.href ? 'active' : ''}
              >{l.text}</a>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};


export default Header;
