import React from 'react';
import gmapStatic from '../images/gmapStatic.png';
import svgPhone from '../images/ic_phone_black_24px.svg';
import svgMap from '../images/ic_map_white_24px.svg';
import svgTime from '../images/ic_access_time_white_24px.svg';

interface Props {
  title: string;
  children: React.ReactNode;
  iconSvg: string;
}

const Card = ({title, children, iconSvg}: Props) => (
  <div className="card">
    <div className="card-title">
      <h4>
        <img src={iconSvg} alt="telefon" className="svg white"/>
        <span style={{marginLeft: 10}}>
           {title}
        </span>

      </h4>
    </div>
    <div className="card-content">
      <p>
        {children}
      </p>
    </div>
  </div>
);


const Jumpotron = () => (
  <section className="section-dark">
    <div className="container">
      <h4>Moderne almenpraksis og tannregulering på Askøy</h4>
      <h4>Tannlege og spesialister i kjeveortopedi</h4>
    </div>
  </section>
);


const FindUs = () => (
  <section className="section-light" id="kart">
    <div className="container">
      <h2>Her finner du oss</h2>

      <div className="flex space-between">
        <div>
          <Card title="Addresse" iconSvg={svgMap}>
            Kleppevegen 75
            5300 Kleppestø
          </Card>
          <Card title="Telefon" iconSvg={svgPhone}>

            Almmenpraksis: <a href="tel:56144100">56 14 41 00</a>
            <br/>
            Tannregulering: <a href="tel:56142460">56 14 24 60</a>
          </Card>
          <Card title="Åpningstider" iconSvg={svgTime}>
            Mandag til torsdag 08.00 - 15.30<br/>
            Fredag 08-00 - 12.00
          </Card>
        </div>
        <div>
          <a
            href="https://www.google.no/maps/place/Torvgarden+Tannklinikk/@60.4214476,5.2099509,17z/data=!3m1!4b1!4m5!3m4!1s0x463cfcc4bb31001b:0x9385dcc802d7bb18!8m2!3d60.4214476!4d5.2121397"
          >
            <img src={gmapStatic} className="map img" alt="link til google maps"/>

          </a>
          <br/>Trykk på bildet for å åpne Google Maps.
        </div>


      </div>
    </div>


  </section>
);

const Start = () => (
  <section>
    <Jumpotron/>
    <FindUs/>

  </section>
);
export default Start;
