import React from 'react';


const Price = () => (
  <section className="section-white" id="pris">
    <div className="container">
      <h2>Prisar for tannregulering</h2>

      <p className="lead">
        Våre prisar var basert på offentlege takstar,
        men sidan helsedirektoratet har satt ned dei offentlege takstane er våre
        prisar no ca 30% høgare for dei fleste behandlingane.
      </p>

      <p>
        Undersøking for å finna ut om ein treng regulering vil normalt koste.

      </p>

      <table className="table table-striped">
        <thead>
        <tr>
          <th>#Takst</th>
          <th>Tekst</th>
          <th>Honorar</th>
          <th>Refusjon</th>
          <th>Eigendel</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>A2</td>
          <td>Undersøking hos spesialist</td>
          <td>937</td>
          <td>385</td>
          <td>552</td>
        </tr>
        <tr>
          <td>804</td>
          <td>Panoramarøntgen</td>
          <td>447</td>
          <td>215</td>
          <td>232</td>
        </tr>
        <tr>
          <td>Sum</td>
          <td></td>
          <td></td>
          <td></td>
          <td>784</td>
        </tr>
        </tbody>
      </table>

      <p>
        Dersom ein skal starte med regulering er det nødvendig med ein meir omfattande undersøking,
        både for planlegging og som dokumentasjon for Helfo.
      </p>

      <p>
        Dette vil til vanleg omfatta:
      </p>

      <table className="table table-striped">
        <thead>
        <tr>
          <th>#Takst</th>
          <th>Tekst</th>
          <th>Honorar</th>
          <th>Refusjon</th>
          <th>Eigendel</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>806</td>
          <td>Skallerøntgen</td>
          <td>601.-</td>
          <td>290.-</td>
          <td>311.-</td>
        </tr>
        <tr>
          <td>802</td>
          <td>Tannrøntgen, per bilde</td>
          <td>52.-</td>
          <td>25.-</td>
          <td>27.-</td>
        </tr>
        <tr>
          <td>704</td>
          <td>Pasientfoto, per bilde</td>
          <td>30.-</td>
          <td>40-60-75-90% av 25.-</td>
          <td></td>
        </tr>

        <tr>
          <td>703</td>
          <td>Sokkla studiemodell, per sett</td>
          <td>1014.-</td>
          <td>40-60-75-90%
            <br/>
            av: 420.-
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <div className="alert alert-warning">
        OBS: For å få refusjon frå trygdekassen ( Helfo ) må pasienten ha henvising frå tannklinikken med seg og
        behandlinga må starte seinast det året ein fyller 20.
      </div>

      <h2>Behandling</h2>

      <p>
        Prisen for ei regulering er avhengig av kor omfattande behandlinga er og vil variera mykje.
        Etter våre takstar i 2022 vil prisane vera om lag slik:

      </p>

      <div className="alert alert-info">
        <ul className="list-unstyled">
          <li>
            Innleiande behandling med plate: 10.000.- - 14.000.-
          </li>
          <li>
            Behandling med fast apparatur i ein kjeve: 17.000.- - 21.000.-
          </li>
          <li>
            Behandling med fast apparatur i begge kjevane: 33.000.- - 37.000.-
          </li>
        </ul>
      </div>
      <p>
        Enkelte behanlingar er svært omfattande med mykje dyrt utstyr og kan kosta meir enn 37.000.-
        <br/>
        Trygda (Helfo ) dekker 40 eller 75% av offentleg takst for 1. barn i familien og 60 eller 90% for resten av
        barna
        dersom dei fyller krava for å få refusjon.
      </p>
      <p>
        Helsedirektoratet har redusert offentlige takster de siste årene, slik at dei no er ca. 30% lavare enn våre
        prisar.
        Det blir derfor ein ekstra eigendel på 30% som det ikkje blir gjeve refusjon for.
        <br/>
        Nokre f.eks pasientar med ganespalte får dekka 100% av offentleg takst.
        Trygda betalar refusjonen direkte til oss. Dekking av reiseutgifter må ein henta sjølv, her treng ein oversikt
        over behandlingane frå oss og må hugsa på at det er 6 mnd. foreldingstid.
      </p>

      <p>
        Utførlege reglar fin du på linken under:

      </p>

      <p>
        <a
          href="https://www.helsedirektoratet.no/rundskriv/kapittel-5-stonad-ved-helsetjenester/takster-for-tannbehandling-">
          Folketrygdens stønad til dekning avutgifter til tannbehandling for 2022
        </a>

      </p>

      <p>
        Sist oppdatert : 01.01.2022
      </p>
    </div>
  </section>
);
export default Price;
