import React from 'react';
import imgPlaceholder from '../images/placeholder140x140.svg';


const WORK_KEJEVE = {
  work: 'Kjeveortoped',
  sub: 'Tannregulering'
};
const WORK_TANNLEGE = {
  work: 'Tannlege ',
  sub: 'Almmenpraksis'
};
const WORK_SECERTARY = {
  work: 'Tannhelsesekretær',
  sub: ''
};

const ansatteList = [
  {name: 'Kristoffer Helland ', ...WORK_KEJEVE},
  {name: 'Nils Åge Førsund ', ...WORK_KEJEVE},
  {name: 'Kristiane H. Helland ', ...WORK_KEJEVE},
  {name: 'Einar Eiking', ...WORK_TANNLEGE},
  {name: 'Mona Juvik', ...WORK_SECERTARY},
  {name: 'Marit T Onarheim', ...WORK_SECERTARY},
  {name: 'Oddgunn S Espedal', ...WORK_SECERTARY},
  {name: 'Kristine Lyngbø', ...WORK_SECERTARY},
];

interface AnsatteProfileProps {
  name: string;
  work: string;
  sub: string;
}

const AnsattProfile = ({name, work, sub}: AnsatteProfileProps) => (
  <div className="" style={{margin: 12}}>
    <img src={imgPlaceholder} className="img" alt="Ingen bilde"/>
    <h4>{name}<br/>
      <small>{work}</small>
    </h4>
    <p>
      {sub}
    </p>
  </div>
);

const Ansatte = () => (
  <section className="section-light" id="ansatte">
    <div className="container">
      <h2>Ansatte</h2>

      <div className="flex space-between">
        {ansatteList.map(a => <AnsattProfile key={a.name} {...a}/>)}
      </div>

    </div>
  </section>
);

export default Ansatte;
