import React from 'react';

const Footer = () => (
  <footer className="section-dark">
    <div className="container">
      <div className="flex space-between">
        <FooterLink href="http://tanngarden.no" title="Tanngarden.no" sub="Os"/>
        <FooterLink href="http://laguneparkentannklinikk.no" title="laguneparkentannklinikk.no" sub="Lagunen"/>
      </div>
    </div>

  </footer>
);

interface FooterLinkProps {
  href: string;
  title: string;
  sub: string;
}

const FooterLink = ({href, title, sub}: FooterLinkProps) => (
  <div>
    <h4><a href={href}>{title}</a></h4>
    <p className="gray">{sub}</p>
  </div>
);


export default Footer;
