import React, {Component} from 'react';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
// import 'purecss/build/base.css';
// import 'purecss/build/forms.css';
// import 'purecss/build/buttons.css';
import './index.css';
import Contact from './pages/Contact';
import {Route} from 'react-router-dom';
import Pictures from './pages/Pictures';
import Price from './pages/Price';
import Ansatte from './pages/Ansatte';
import Start from './pages/Start';
import Navbar from './components/Navbar';
import Footer from './components/Footer';


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="site">
          <Navbar/>

          <Route exact={true} path={'/'} component={Start}/>
          <Route exact={true} path={'/ansatte'} component={Ansatte}/>
          <Route exact={true} path={'/bilder'} component={Pictures}/>
          <Route exact={true} path={'/kontakt'} component={Contact}/>
          <Route exact={true} path={'/pris'} component={Price}/>

          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
