import React, {Component} from 'react';

const getEndpoint = () => {
  if (window.location.hostname.indexOf('localhost') >= 0) {
    return '/epostTest';
  }
  return '/epost';
};

interface State {
  form: {
    name: string;
    email: string;
    message: string;
  },
  isFetching: boolean;
  response?: any;
  error?: any;


}

class Contact extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      form: {
        name: '',
        email: '',
        message: '',
      },
      isFetching: false,
      response: undefined,
      error: undefined
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setFormField = this.setFormField.bind(this);
  }

  handleSubmit(event: any) {
    event.preventDefault();
    console.log('onSubmit');
    this.setState({isFetching: true});
    fetch(getEndpoint(), {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.form)
    }).then(d => d.json())
      .then(d => {
        console.log('d', d);
        if (d && d.message && d.message.indexOf('Epost ble sendt') > -1) {
          this.setState({response: d, isFetching: false})
        } else {
          this.setState({error: {msg: 'feil'}, isFetching: false})
        }

      })
      .catch(e => this.setState({error: e, isFetching: false}));

  }

  setFormField(o: any) {
    this.setState({form: {...this.state.form, ...o}});
  }


  render() {
    const {form, isFetching, response, error} = this.state;
    const {name, email, message} = form;

    const isValid = name.length > 2 && email.length > 3 && message.length > 5;
    const isButtonDisabled = !isValid || isFetching || response || error;

    const isFormDisabled = true;
    return (
      <section className="section-white" id="kontakt">
        <div className="container">
          <h2>Kontakt oss</h2>


          {isFormDisabled && <p>Kontakt oss på kontakt@torvgarden-tannklinikk.no</p>}
          {!isFormDisabled &&
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="inputEmail">Epost</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={e => this.setFormField({email: e.target.value})}
                className="form-control"
                placeholder="Din epostaddresse"
              />
            </div>

            <div className="form-group">
              <label htmlFor="inputName">Navn</label>
              <input
                type="text"
                value={name}
                onChange={e => this.setFormField({name: e.target.value})}
                className="form-control"
                placeholder="Ditt navn"
                name="name"
              />
            </div>
            {/*<!-- Gjemt felt. For å lure botter.. -->*/}
            <div className="form-group" style={{display: 'none'}}>
              <label id='other_email_label' htmlFor='other_email'>Please leave blank:</label>
              <input type='text' name='other_email' id='other_email'/>
            </div>

            <div className="form-group">
              <label htmlFor="inputMessage">Melding</label>
              <textarea
                value={message}
                onChange={e => this.setFormField({message: e.target.value})}
                className="form-control"
                name="message"
                rows={3}
                id="inputMessage"
              />
            </div>

            <button type="submit" className="btn btn-default" disabled={isButtonDisabled}>
              {isFetching ? 'Sender melding ....' : 'Send melding'}
            </button>

            <div className="spacer"></div>
            {response &&
            <div className="alert alert-success">
              Meldingen er sendt!
            </div>
            }
            {error &&
            <div className="alert alert-danger">
              En ukjent feil opptso. Venligst kontakt oss på epost :
              kontakt@torvgarden-tannklinikk.no
            </div>
            }


          </form>
          }
        </div>
      </section>
    )
  }
}

export default Contact;
