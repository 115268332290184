import React from 'react';
import img1 from '../images/1.jpg';
import img2 from '../images/2.jpg';
import img3 from '../images/3.jpg';
import img4 from '../images/4.jpg';
import img5 from '../images/5.jpg';
import img6 from '../images/6.jpg';
import img7 from '../images/7.jpg';
import img8 from '../images/8.jpg';
import img9 from '../images/9.jpg';

const mockPictures = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
const Pictures = () => (
  <section className="section-white" id="bilder">
    <div className="container">
      <h2>Bilder</h2>
      <h3>Diverse bilder</h3>

      <div className="flex space-between">

        {mockPictures.map((img, i) =>
          <img key={i} src={img} className="tt-img"
               alt="tannlege kontor"/>
        )}

      </div>

    </div>
  </section>
);
export default Pictures;
